import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import Loader from "../../shared/loader/Loader";
import Ceo from "../../shared/seo/Seo";
import MetaDecorator from "../../shared/MetaDecorator";

const About = () => {

    const wrapperEl = useRef(null);
    const imgRef = useRef(null);
    const [properties, setProperties] = useState({
        width: null,
        height: null,
        left: null,
        top: null
    })
    const {isLoadingAbout, isErrorAbout, aboutData} = useSelector(state => state.about)
    const [socialRowLength, setSocialRowLength] = useState(0)

    useEffect(() => {
        let socialLength = 0;
        if (aboutData.youtubeLink !== '') {
            socialLength += 1
        }
        if (aboutData.facebookLink !== '') {
            socialLength += 1
        }
        if (aboutData.instagramLink !== '') {
            socialLength += 1
        }
        if (aboutData.vimeoLinkSocial !== '') {
            socialLength += 1
        }
        setSocialRowLength(socialLength)

        setTimeout(() => {
            onLoad()
        }, 500)
    }, [aboutData])

    const TILT_MAX_ANGLE_X = 25;
    const TILT_MAX_ANGLE_Y = 5;
    const AUTORESET_ON_LEAVE = true;

    let clientPosition = {
        x: 0,
        y: 0,
        xPercentage: 0,
        yPercentage: 0,
    }
    let tiltAngleX = 0;
    let tiltAngleY = 0;
    let updateAnimationId = null;

    const onLoad = () => {
        setSizeAndPosition();
    }

    const setSizeAndPosition = () => {
        setProperties({
            width: wrapperEl.current.offsetWidth,
            height: wrapperEl.current.offsetHeight,
            left: wrapperEl.current.getBoundingClientRect().left,
            top: wrapperEl.current.getBoundingClientRect().top
        });
    }

    const onEnter = (event) => {
        imgRef.current.style.willChange = 'transform';
        setTransition();
    };

    const onMove = (event) => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            return null;
        }
        if (updateAnimationId) {
            cancelAnimationFrame(updateAnimationId);
        }
        processInput(event);
        update(event.type);
        updateAnimationId = requestAnimationFrame(renderFrame);
    };

    const onLeave = () => {
        setTransition();
        const autoResetEvent = new CustomEvent('autoreset');
        onMove(autoResetEvent);
    };

    const processInput = (event) => {
        switch (event.type) {
            case 'mousemove':
                clientPosition.x = event.clientX;
                clientPosition.y = event.clientY;

                break;
            case 'autoreset':
                if (AUTORESET_ON_LEAVE) {
                    tiltAngleX = 0;
                    tiltAngleY = 0;

                }
                break;
        }
    };

    const update = (eventType) => {
        if (eventType === 'autoreset') {
            return;
        }

        if (eventType !== 'deviceorientation') {
            const {x, y} = clientPosition;
            // calculate client x/y position
            clientPosition.xPercentage = ((y - properties.top) / properties.height) * 200 - 100;
            clientPosition.yPercentage = ((x - properties.left) / properties.width) * 200 - 100;
        }
        // set range [-100,100]
        clientPosition.xPercentage = Math.min(Math.max(clientPosition.xPercentage, -100), 100);
        clientPosition.yPercentage = Math.min(Math.max(clientPosition.yPercentage, -100), 100);
        // Calculate tilt angle x/y
        tiltAngleX = (clientPosition.xPercentage * TILT_MAX_ANGLE_X) / 100;
        tiltAngleY = -((clientPosition.yPercentage * TILT_MAX_ANGLE_Y) / 100);
    };


    const renderFrame = () => {
        imgRef.current.style.transform = `perspective(900px) rotateX(${tiltAngleX}deg) rotateY(${tiltAngleY}deg)`;
    };


    const setTransition = () => {
        const duration = 1800;
        imgRef.current.style.transition = `all ${duration}ms cubic-bezier(.03,.98,.52,.99)`;
    };

    return (
        <div>
            <MetaDecorator title='O nas - Lemon Wedding'/>
            <section className="logo-section">
                <div className="img-wrapper"
                     ref={wrapperEl}
                     onMouseEnter={onEnter}
                     onMouseMove={onMove}
                     onMouseLeave={onLeave}>
                    <img ref={imgRef} src="/lemon-sniff-media-logo.svg" alt=""/>
                </div>
            </section>
            <div className="container">
                {!isLoadingAbout ?
                    <section className="persons-section">
                        {aboutData.team.map(player => {
                            return (
                                <div className="person-box" key={player.id}>
                                    <div className="photo-col">
                                        <img
                                            src={`https://wedding.lemonsniff.pl/assets/img/uploads/team/${player.id}.jpg`}
                                            alt=""/>
                                    </div>
                                    <div className="info-col">
                                        <div className="name">{player.name}</div>
                                        <div className="role">{player.position}</div>
                                        <div className="social-box">
                                            {player.facebook &&
                                            <a href={player.facebook}>
                                                <i className="icon-facebook"></i>
                                            </a>
                                            }
                                            {player.instagram &&
                                            <a href={player.instagram}>
                                                <i className="icon-instagram"></i>
                                            </a>
                                            }
                                            {player.youtube &&
                                            <a href={player.youtube} className="youtube">
                                                <i className="icon-youtube"></i>
                                            </a>
                                            }
                                            {player.email &&
                                            <a href={`mailto: ${player.email}`} className="email">
                                                <i className="icon-email"></i>
                                            </a>
                                            }
                                        </div>
                                        <div className="description"
                                             dangerouslySetInnerHTML={{__html: player.description}}/>
                                    </div>
                                </div>
                            )
                        })}
                    </section> :
                    <Loader/>
                }
                {socialRowLength > 0 &&
                <section className={`social-section ${aboutData.seo_about?.length > 0 ? '' : 'last-section'}`}>
                    <h2>Obserwuj nas <span>na</span></h2>
                    <div className={`social-row ${socialRowLength === 3 ? 'three-items' : ''}`}>
                        {aboutData.instagramLink &&
                        <a target="_blank" data-text="Instagram" href={`${aboutData.instagramLink}`}>Instagram</a>
                        }
                        {aboutData.youtubeLink &&
                        <a target="_blank" data-text="Youtube" href={`${aboutData.youtubeLink}`}
                           className="fill">Youtube</a>
                        }
                        {aboutData.facebookLink &&
                        <a target="_blank" data-text="Facebook" href={`${aboutData.facebookLink}`}>Facebook</a>
                        }
                        {aboutData.vimeoLinkSocial &&
                        <a target="_blank" data-text="Vimeo" href={`${aboutData.vimeoLinkSocial}`}>Vimeo</a>
                        }
                    </div>
                </section>
                }
                <Ceo
                    seoPage={aboutData.seo_about}
                />
            </div>
        </div>
    )
}

export default About;
