import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const CustomCursor = () => {

    const {pathname} = useLocation();
    const lemonDotRef = useRef()
    const [position, setPosition] = useState({x: 0, y: 0});
    const [loaded, setLoaded] = useState(false);
    const {extraTextHovered, defaultHovered, hideCursor} = useSelector(state => state.cursorHover)

    useEffect(() => {
        window.addEventListener('mousemove', mousemove);
        if (loaded) {
            loop();
        }
    }, [pathname, loaded])

    let x = position.x;
    let y = position.y;
    let posX = x;
    let posY = y;

    const mousemove = (e) => {
        x = e.pageX;
        y = e.pageY;
        setPosition({x: e.pageX, y: e.pageY});
        lemonDotRef.current.classList.add('loaded');
        setLoaded(true)
    }

    const move = () => {
        posX += (x - posX) * 0.09;
        posY += (y - posY) * 0.09;
        const pos = (posX - 5) + "px, " + (posY - 5) + "px, 0px";
        const mov = "translate3d(" + pos + ")";
        lemonDotRef.current.style.mozTransform = mov;
        lemonDotRef.current.style.webkitTransform = mov;
        lemonDotRef.current.style.transform = mov;
    }

    const loop = () => {
        move();
        requestAnimationFrame(loop);
    }

    return (
        <div ref={lemonDotRef}
             className={`lemon-cursor ${defaultHovered ? 'default-hovered' : ''} ${extraTextHovered ? 'photo-hovered' : ''} ${hideCursor ? 'd-none' : ''}`}>
            <div></div>
        </div>
    )
}

export default CustomCursor;
