import React from "react";
import Modal from "./shared/modal/Modal";
import ContactForm from "./shared/contact/ContactForm";
import ContactData from "./shared/ContactData";
import Routes from "./Routes";
import {useLocation} from "react-router";

const App = () => {
    const location = useLocation()

    return (
        <>
            <Routes location={location}/>
            <Modal>
                <ContactForm/>
                <div className="address-col">
                    <ContactData/>
                </div>
            </Modal>
        </>
    )
}

export default App;
