import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from "react-redux";
import {closeModal, contactFormSendAgain} from "../../../actions";


const Modal = (props) => {
    const dispatch = useDispatch();
    const [closingModal, setClosingModal] = useState(false)
    const {closing, show} = useSelector(state => state.modalShow);
    const {isSent} = useSelector(state => state.contactForm)
    const [showCloseIcon, setShowCloseIcon] = useState(false)

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setShowCloseIcon(true)
            }, 2300)
        } else {
            setShowCloseIcon(false)
        }
    }, [show])

    const closeModalFunction = () => {
        setClosingModal(true)
        setTimeout(() => {
            dispatch(closeModal())
            setClosingModal(false)
        }, 500)
        dispatch(contactFormSendAgain());
    };
    if (show) {
        window.addEventListener('keydown', function (event) {
            if (event.key === 'Escape') {
                closeModalFunction()
            }
        })
    }

    if (show) {
        return (
            ReactDOM.createPortal(
                <React.Fragment>
                    <div
                        className={`contact-form-wrapper ${isSent !== null ? 'form-sent' : ''} ${closingModal || closing ? 'closing' : ''}`}
                        role="dialog"
                        onClick={(e) => e.stopPropagation()}>
                        <div className="container relative">
                            {showCloseIcon &&
                            <>
                                <div className="logo-place">
                                    <img src="/lemon-sniff-media-logo.svg" alt="logo"/>
                                </div>
                                <button type="button" className="modal-close-button" onClick={closeModalFunction}>
                                    <i className="icon-x"></i>
                                    <svg className="circle-icon">
                                        <circle cx="25" cy="25" r="25"/>
                                    </svg>
                                </button>
                            </>
                            }
                            <div className="contact-flex-row">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </React.Fragment>, document.body
            )
        )
    }
    return null
}

export default Modal;
