import React, {useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getMainRealizations, textItemHover, textItemLeave} from '../../../actions'
import Loader from "../../shared/loader/Loader";
import {Link} from "react-router-dom";
import {LazyImage} from "../../shared/lazyImg/LazyImage";
import Reveal from "react-reveal/Reveal";
import {useWindowSize} from "../../hooks/windowSize";

const Realizations = () => {

    const dispatch = useDispatch();
    const photoBoxRef = useRef([]);
    const {data, isLoading, isError} = useSelector(state => state.realizations)
    const size = useWindowSize()

    useEffect(() => {
        if (data.length === 0) {
            dispatch(getMainRealizations())
        }
        if (data.length > 0 && size > 1200) {
            document.querySelector('.main-page-wrapper').addEventListener('scroll', parallaxPhoto);
            return () => document.querySelector('.main-page-wrapper').removeEventListener('scroll', parallaxPhoto);
        }
    }, [data.length])

    const parallaxPhoto = () => {

        photoBoxRef.current.forEach(singlePhoto => {
            const photoOffsetTop = singlePhoto.getBoundingClientRect().top;
            const photoHeight = singlePhoto.getBoundingClientRect().height;
            const imgHeight = singlePhoto.querySelector('.photo').getBoundingClientRect().height
            const imgWidth = singlePhoto.querySelector('.photo').getBoundingClientRect().width
            const windowHeight = window.innerHeight;
            const scroll = photoOffsetTop - windowHeight
            const transformValue = ((windowHeight + photoHeight) + scroll) / (windowHeight + photoHeight) * (imgHeight - photoHeight)
            const transformStyle = `translate(-50%, -${transformValue}px)`
            if (photoOffsetTop <= windowHeight && -photoOffsetTop <= photoHeight && imgHeight > imgWidth) {
                singlePhoto.querySelector('.photo').style.transform = transformStyle;
                singlePhoto.querySelector('.photo').style.top = 0;
            }
        });
    }


    if (isError === true) {
        return <div>Coś poszło nie tak</div>
    }

    if (isLoading === true) {
        return <Loader/>
    }

    return data.map((realization, i) => {
        return (
            <Link
                className="realization"
                to={`/${realization.category_url}/${realization.name_url}`}
                key={realization.id}
                onMouseEnter={() => {
                    dispatch(textItemHover())
                }}
                onMouseLeave={() => {
                    dispatch(textItemLeave())
                }}
                ref={el => photoBoxRef.current[i] = el}
            >
                <div className="photo-col">
                    <LazyImage className='photo'
                               src={`https://wedding.lemonsniff.pl/assets/img/uploads/${realization.id}.jpg`}/>
                </div>
                <div className="info-col">
                    <h4 className="title">{realization.title}</h4>
                    <div className="description">{realization.category_name}</div>
                </div>
            </Link>
        )
    })
}

export default Realizations;
