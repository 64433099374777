import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import Realizations from "../realizations/Realizations";
import InitialVideo from "./initialVideo/InitialVideo";
import {Link} from "react-router-dom";
import Categories from "../categories/Categories";
import {useDispatch, useSelector} from "react-redux";
import {defaultItemHover, defaultItemLeave, getAboutData, getCategories, getClientsMainPage} from "../../../actions";
import Reveal from "react-reveal/Reveal";
import Seo from "../../shared/seo/Seo";
import Slider from "./Slider/Slider";
import ContactButton from "../../shared/contact/ContactButton";
import MetaDecorator from "../../shared/MetaDecorator";

const Main = () => {

    const dispatch = useDispatch();
    const {isLoading, isError, categoriesData} = useSelector(state => state.categories)
    const {isLoadingAbout, isErrorAbout, aboutData} = useSelector(state => state.about)
    const {isLoadingRealization} = useSelector(state => state.realizations)
    const fullHeightRef = useRef();


    useEffect(() => {
        if (categoriesData.length === 0) {
            dispatch(getCategories());
        }
    }, [])
    return (
        <div className="full-height-wrapper" ref={fullHeightRef} style={{position: "static!important"}}>
            <MetaDecorator title='Lemon Wedding - Produkcja video'/>
            <div className="container">
                <section className="title-section">
                    <Reveal effect="fadeInUp" effectOut="fadeOutLeft">
                        <h1
                            onMouseEnter={() => {
                                dispatch(defaultItemHover())
                            }}
                            onMouseLeave={() => {
                                dispatch(defaultItemLeave())
                            }}
                            className="hover-element">
                            <div dangerouslySetInnerHTML={{__html: aboutData.main_description}}/>
                        </h1>
                    </Reveal>
                </section>
            </div>
            <InitialVideo
                vimeoLink={aboutData.vimeoLink}
                videoVersion={aboutData.videoVersion}
                isLoadingAbout={isLoadingAbout}
                isErrorAbout={isErrorAbout}
            />
            <div className="container">
                <section className="about-section">
                    <h3 dangerouslySetInnerHTML={{__html: aboutData.description}}/>
                    <Link to="/o-nas" className="underline-button">Dowiedz się więcej o nas</Link>
                </section>
                {!isLoading &&
                <Categories
                    categoriesData={categoriesData}
                    isLoading={isLoading}
                    isError={isError}
                />
                }
                <section className="packages-section">
                    <h2><span>Nasze</span> pakiety</h2>
                    <div className="packages-row">
                        <div className="package-col">
                            <div className="package">
                                <div className="title">Basic</div>
                                <div className="price">4000zł</div>
                                <div className="divider"><span>Pakiet obejmuje</span></div>
                                <ul>
                                    <li>Ekipa od przygotowań do <strong>20:00</strong></li>
                                    <li>Film do wesela<strong>do 30 min.</strong></li>
                                    <li>Short z wesela <strong>do 5 min.</strong></li>
                                </ul>
                                <div className="button-place grey">
                                    <ContactButton
                                        buttonName="Skontaktuj się"
                                        inHeader
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="package-col">
                            <div className="package">
                                <div className="title">Gold</div>
                                <div className="price">5000zł</div>
                                <div className="divider"><span>Pakiet obejmuje</span></div>
                                <ul>
                                    <li>Ekipa od przygotowań do <strong>00:30</strong></li>
                                    <li>Film do wesela<strong>do 60 min.</strong></li>
                                    <li>Short z wesela <strong>do 5 min.</strong></li>
                                    <li>Ujęcia <strong>z drona.</strong></li>
                                </ul>
                                <div className="button-place grey">
                                    <ContactButton
                                        buttonName="Skontaktuj się"
                                        inHeader
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="package-col">
                            <div className="package premium">
                                <div className="title">Premium</div>
                                <div className="price">8000zł</div>
                                <div className="divider"><span>Pakiet obejmuje</span></div>
                                <ul>
                                    <li>Ekipa od przygotowań do <strong>00:30</strong></li>
                                    <li>Film do wesela<strong>do 60 min.</strong></li>
                                    <li>Short z wesela <strong>do 5 min.</strong></li>
                                    <li>Ujęcia <strong>z drona.</strong></li>
                                    <li>Sesja <strong>plenerowa.</strong></li>
                                    <li>Podziękowania <strong>dla rodziców.</strong></li>
                                </ul>
                                <div className="button-place">
                                    <ContactButton
                                        buttonName="Skontaktuj się"
                                        inHeader
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="extra-services-section">
                    <div className="flex-row">
                        <div className="left-col">
                            <h2><span>Dodatkowe</span> usługi</h2>
                            <div className="desc">Możliwe do dokupienia do pakietów <br/> <strong>Basic</strong> oraz <strong>Gold</strong></div>
                        </div>
                        <div className="right-col">
                            <ul>
                                <li>
                                    <div className="label">Fotograf</div>
                                    <strong className="value">(oferta indywidualna)</strong>
                                </li>
                                <li>
                                    <div className="label">Podziękowania dla rodziców</div>
                                    <strong className="value">1600 zł</strong>
                                </li>
                                <li>
                                    <div className="label">Sesja Plenerowa</div>
                                    <strong className="value">1600 zł</strong>
                                </li>
                                <li>
                                    <div className="label">Dron</div>
                                    <strong className="value">1000 zł</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="realizations-section">
                    <h2><span>Wybrane</span> realizacje</h2>
                    <div className="realizations-wrapper">
                        {!isLoadingRealization &&
                        <Realizations/>
                        }
                    </div>
                </section>
                <section className="quote-section">
                    <h2><span>Opinie</span> klientów</h2>
                    <Slider />
                </section>
                <Seo
                    seoPage={aboutData.seo_main_page}
                />
            </div>
        </div>
    )
}


export default Main;
