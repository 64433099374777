import React, {useEffect, useRef} from "react";
import Header from "./shared/Header";
import {Route, Switch} from "react-router-dom";
import Main from "./pages/mainPage/Main";
import About from "./pages/about/About";
import Realization from "./pages/realizations/Realization";
import Footer from "./shared/Footer";
import Category from "./pages/categories/Category";
import CustomCursor from "./mouseTracking/MouseTruckingDot";
import NotFound from "./pages/404";
import ScrollToTop from "./pageTransition/PageTransition";
import {useWindowSize} from "./hooks/windowSize";
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {defaultCursor} from "../actions";
import {useDispatch} from "react-redux";

const Routes = ({location}) => {

    const pageWrapperRef = useRef(null);
    const mainPageWrapperRef = useRef(null);
    const dispatch = useDispatch();
    const size = useWindowSize();
    useEffect(() => {
        dispatch(defaultCursor);
        setTimeout(() => {
            document.body.classList.remove('new-page')
        }, 600)
    }, [location.pathname])

    return (
        <div className="main-page-wrapper" ref={mainPageWrapperRef}>
            <div className="page-wrapper" ref={pageWrapperRef}>
                <div className="section-wrapper">
                    <Header
                        pageWrapperRef={pageWrapperRef}
                        mainPageWrapperRef={mainPageWrapperRef}
                    />
                    {size > 767 &&
                    <CustomCursor/>
                    }
                    <ScrollToTop/>
                    <TransitionGroup className="animation-wrapper">
                        <CSSTransition
                            timeout={500}
                            classNames='fade'
                            key={location.key}
                        >
                            <Switch location={location}>
                                <Route path="/" exact component={Main}/>
                                <Route path="/o-nas" exact component={About}/>
                                <Route path="/:categoryName" exact component={Category}/>
                                <Route path="/:categoryName/:name" exact component={Realization}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <Footer
                    pageWrapperRef={pageWrapperRef}
                    mainPageWrapperRef={mainPageWrapperRef}
                />
            </div>
        </div>
    )
}


export default Routes;
