import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, NavLink} from 'react-router-dom'
import ContactButton from "./contact/ContactButton";
import {useDispatch, useSelector} from "react-redux";
import {closeModal, closingModal, contactFormSendAgain, openModal} from "../../actions";
import {useWindowSize} from "../hooks/windowSize";

const Header = ({mainPageWrapperRef, pageWrapperRef}) => {
    const {pathname} = useLocation()
    const headerRef = useRef(null)
    const inputRef = useRef(null)
    const [showMenu, setShowMenu] = useState(false)
    const [closing, setClosing] = useState(false)
    const {show} = useSelector(state => state.modalShow);
    const {isSent} = useSelector(state => state.contactForm);
    const {aboutData} = useSelector(state => state.about);
    const dispatch = useDispatch();
    const size = useWindowSize();

    const headerSlideDown = (e) => {
        const scroll = e.srcElement.scrollTop
        const footer = document.querySelector('footer')
        const headerHeight = headerRef.current.clientHeight;
        const untilTransform = pageWrapperRef.current.clientHeight - footer.clientHeight;
        //kiedy się ma skonczyc
        const footerOffset = footer.offsetTop - headerRef.current.clientHeight;
        const startTransform = footerOffset;
        //kiedy ma sie zaczac transformowac
        const transformValue = (untilTransform - scroll) / (untilTransform - startTransform) * headerHeight
        //wartosc - ostatni skladnik to o ile ma sie transformowac
        const transformStyle = `-${headerHeight - transformValue}px`
        headerRef.current.style.top = `0`;
        switch (true) {
            case scroll >= footerOffset && size > 767:
                headerRef.current.style.top = transformStyle;
                break;
            case scroll === 0:
                headerRef.current.classList.remove('fixed');
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        mainPageWrapperRef.current.addEventListener('scroll', headerSlideDown);
    }, [pathname, mainPageWrapperRef, headerSlideDown])

    const openMobileForm = () => {
        dispatch(openModal())
        inputRef.current.classList.add('active')
        mainPageWrapperRef.current.classList.add('overflow-hidden');
    }
    const closeMobileForm = () => {
        dispatch(closingModal())
        inputRef.current.classList.remove('active')
        mainPageWrapperRef.current.classList.remove('overflow-hidden');
        if (isSent !== null) {
            setTimeout(() => {
                dispatch(contactFormSendAgain());
            }, 1000)
        }
        setTimeout(() => {
            dispatch(closeModal())
            dispatch(closingModal())
        }, 1000)
    }

    const handleMobileForm = () => {
        if (!show) {
            openMobileForm()
        } else if (show && showMenu) {
            closeMobileForm()
            setShowMenu(false)
        } else {
            closeMobileForm()
        }
    }

    const openMobileMenu = () => {
        setShowMenu(true)
        mainPageWrapperRef.current.classList.add('overflow-hidden');
    }

    const closeMobileMenu = () => {
        setClosing(true)
        mainPageWrapperRef.current.classList.remove('overflow-hidden');
        setTimeout(() => {
            setClosing(false)
        }, 1000)
        setShowMenu(false)
    }
    const handleMobileMenu = () => {
        if (showMenu && show) {
            closeMobileForm()
            setShowMenu(false)
        } else if (show) {
            closeMobileForm()
        } else if (showMenu) {
            closeMobileMenu()
        } else if (!showMenu) {
            openMobileMenu()
        }

    }
    const closingWhenRouteNotChanged = () => {
        if (showMenu) {
            closeMobileMenu()
        }
        if (show) {
            closeMobileForm()
        }
    }

    function getScrollbarWidth(): number {

        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        outer.parentNode.removeChild(outer);

        return scrollbarWidth;

    }

    return (
        <header className={`header-space  ${showMenu ? 'mobile-menu' : ''} ${closing ? 'closing' : ''}`}>
            <div className="header" ref={headerRef} style={{right: `${getScrollbarWidth()}px`}}>
                <div className="container">
                    <nav>
                        <Link to="/" className="logo-place" onClick={closingWhenRouteNotChanged}>
                            <img src="/lemon-sniff-media-logo.svg" alt="logo"/>
                        </Link>
                        <ul>
                            <li onClick={closingWhenRouteNotChanged}>
                                <NavLink activeClassName="active" className="translating-button" to="/o-nas">
                                    <em>
                                        <span data-text="O nas">O nas</span>
                                    </em>
                                </NavLink>
                            </li>
                            <li className="header-contact-btn">
                                <ContactButton
                                    buttonName="Skontaktuj się"
                                    inHeader
                                />
                            </li>
                            <li className="mobile-contact-data">
                                <div className="social-box">
                                    <div className="label">Media społecznośiowe</div>
                                    {aboutData.youtubeLink &&
                                    <a rel="noopener noreferrer" target="_blank" href={`${aboutData.youtubeLink}`}
                                       className="icon">
                                        <span data-text="Youtube">Youtube</span>
                                        <i className="icon-youtube"></i>
                                    </a>
                                    }
                                    {aboutData.instagramLink &&
                                    <a rel="noopener noreferrer" target="_blank" href={`${aboutData.instagramLink}`}
                                       className="icon">
                                        <span data-text="Instagram">Instagram</span>
                                        <i className="icon-instagram"></i>
                                    </a>
                                    }
                                    {aboutData.facebookLink &&
                                    <a rel="noopener noreferrer" target="_blank" href={`${aboutData.facebookLink}`}
                                       className="icon">
                                        <span data-text="Facebook">Facebook</span>
                                        <i className="icon-facebook"></i>
                                    </a>
                                    }
                                    {aboutData.vimeoLinkSocial &&
                                    <a rel="noopener noreferrer" target="_blank" href={`${aboutData.vimeoLinkSocial}`}
                                       className="icon">
                                        <span data-text="Facebook">Facebook</span>
                                        <i className="icon-vimeo"></i>
                                    </a>
                                    }
                                </div>
                                <div>
                                    <div className="label">E-mail</div>
                                    <a href={`mailto:${aboutData.email}`} className="value">{aboutData.email}</a>
                                </div>
                                <div>
                                    <div className="label">Telefon</div>
                                    <div className="value">
                                        <a href={`tel:${aboutData.phone}`}>{aboutData.phone}</a>
                                        <a href={`tel:${aboutData.phone2}`}>{aboutData.phone2}</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="mobile-nav">
                            <button type="button" className="mobile-contact-btn" onClick={handleMobileForm}>
                                <i className="icon-email"></i>
                            </button>
                            <div className="hamburger-btn">
                                <label>
                                    <input type="checkbox"
                                           ref={inputRef}
                                           checked={showMenu}
                                           className={show ? 'active' : ''}
                                           onClick={handleMobileMenu}/>
                                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50" cy="50" r="30"/>
                                        <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35"/>
                                        <path className="line--2" d="M0 49h70"/>
                                        <path className="line--3" d="M0 57h62c13 0 6-28-4-18L35 65"/>
                                    </svg>
                                </label>
                            </div>
                        </div>

                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;
