import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {  Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import React, {useEffect, useState} from "react";
import myAxios from "../../../../apis/myAxios";

const Slider = () => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorData, setError] = useState(true)

    useEffect(() => {
        myAxios.get(`/reviews`)
            .then(response => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                setError(error.response)
            })
    }, [])

    SwiperCore.use([Pagination]);

    return (
        <div className="slider-section">
            <div className="container">
                <img src="/quote.svg" alt=""/>
                <Swiper
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                >
                    {data.map(quote => {
                        return (
                            <SwiperSlide>
                                <div className="desc">
                                    {quote.description}
                                </div>
                                <div className="person">{quote.name}</div>
                                <div className="extra-txt">Potwierdzona opinia</div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}

export default Slider;
